<template>
  <div style="max-height: calc(100vh - 179px); overflow-y: auto">
    <v-data-table
      :loading="loading"
      :search="search"
      :page.sync="page"
      :headers="headers"
      :items="items"
      :items-per-page="5"
      item-key="id"
      fixed-header
      sort-by="order_num"
      sort-desc
      must-sort
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          single-line
          :label="$t('configurations.search_layer')"
          prepend-inner-icon="mdi-magnify"
          outlined
        />
      </template>
      <template
        v-if="hasUserPermissionToViewEditOrManage('LAYERS')"
        v-slot:item.actions="{ item }"
      >
        <v-menu bottom absolute>
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="hasUserPermissionToManage('LAYERS')"
              @click="remove(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="hasUserPermissionToViewEditOrManage('LAYERS')"
              @click="edit(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      page: 1,
      loading: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("global.view_order"),
          value: "order_num",
          width: "200px",
          align: "center",
        },
        {
          text: this.$t("global.code"),
          value: "code",
        },
        {
          text: this.$t("global.name"),
          value: "name",
        },
        {
          text: this.$t("global.description"),
          value: "description",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.layers.layersList;
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("layers/GET_LAYERS").then(() => {
      this.loading = false;
    });
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("remove", item);
    },
  },
};
</script>
