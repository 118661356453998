<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon>{{ $t("configurations.create_layer") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="overflow:auto;height:50vh">
        <v-form ref="form" class="mt-10">
          <v-text-field
            counter="50"
            maxlength="50"
            v-model="item.code"
            required
            :label="$t('global.code')"
            :rules="codeRules"
            outlined
          />
          <v-text-field
            counter="150"
            maxlength="150"
            v-model="item.name"
            required
            :label="$t('global.name')"
            :rules="nameRules"
            outlined
          />
          <v-text-field
            counter="250"
            maxlength="250"
            v-model="item.description"
            :label="$t('global.description')"
            outlined
          />
          <v-switch
            v-model="item.active"
            :label="$t('global.active_by_omission')"
          ></v-switch>
          <v-select
            v-model="item.locode"
            :items="locodes"
            :label="$t('global.port_scale')"
            item-text="name"
            item-value="code"
            outlined
            disabled
          />
          <v-select
            v-model="item.type"
            :items="types"
            required
            :label="$t('global.layer_type')"
            item-text="text"
            item-value="value"
            :rules="typeRules"
            outlined
          />
          <v-text-field
            v-model="item.order_num"
            type="number"
            :label="$t('global.order')"
            :rules="orderRules"
            outlined
            onkeydown="return event.keyCode !== 69"
          >
            <template v-slot:append-outer>
              <v-btn depressed icon @click="incrementOrder">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn depressed icon @click="decrementOrder">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div v-if="item.type == 'WMS'">
            <v-text-field
              counter="200"
              maxlength="200"
              v-model="item.wms_url"
              required
              :label="$t('global.service_url')"
              :rules="wmsUrlRules"
              outlined
            />
            <v-text-field
              counter="200"
              maxlength="200"
              v-model="item.wms_layers"
              required
              :label="$t('global.layer_layers')"
              :rules="wmsLayersRules"
              outlined
            />
            <v-text-field
              counter="200"
              maxlength="200"
              v-model="item.wms_format"
              required
              :label="$t('global.format')"
              :rules="wmsFormatRules"
              outlined
            />
            <v-select
              v-model="item.wms_crs"
              :items="epsgs"
              required
              :label="$t('global.epsg')"
              item-text="text"
              item-value="value"
              :rules="wmsEpsgRules"
              outlined
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save">
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import configs from "@/helpers/configs";
const DEFAULT_ITEM = {
  code: "",
  name: "",
  description: null,
  active: false,
  locode: null,
  order_num: 0,
  type: "WMS",
  wms_url: "",
  wms_layers: "",
  wms_format: "",
  wms_crs: "EPSG:4326",
};
export default {
  props: ["open"],
  data() {
    return {
      locodes: configs.getLocodes(),
      codeRules: [(v) => !!v || this.$t('global.code_rules')],
      nameRules: [(v) => !!v || this.$t('global.name_rules')],
      typeRules: [(v) => !!v || this.$t('global.type_rules')],
      wmsUrlRules: [(v) => !!v || this.$t('global.wms_url_rules')],
      wmsLayersRules: [(v) => !!v || this.$t('global.wms_layer_rules')],
      wmsFormatRules: [(v) => !!v || this.$t('global.wms_format_rules')],
      wmsEpsgRules: [(v) => !!v || this.$t('global.wms_epsg_rules')],
      orderRules: [],
      item: Object.assign({}, DEFAULT_ITEM),
      types: [
        {
          text: "WMS",
          value: "WMS",
        },
      ],
      epsgs: [
        {
          text: "EPSG:4326",
          value: "EPSG:4326",
        },
        {
          text: "EPSG:3857",
          value: "EPSG:3857",
        },
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    currentLocode() {
      return this.$store.state.user.account.locode;
    },
  },
  watch: {
    open(val) {
      if (val) {
        if (this.$refs.form) this.$refs.form.resetValidation();
        this.item = Object.assign({}, DEFAULT_ITEM);
        this.item.locode = this.currentLocode.code;
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        let layer = Object.assign({}, this.item);
        if (layer.type == "WMS") {
          layer.source_url = layer.wms_url;
          layer.content = JSON.stringify({
            layers: layer.wms_layers,
            crs: layer.wms_crs,
            format: layer.wms_format,
          });
        }
        this.$store.dispatch("layers/CREATE_LAYER", layer).then(() => {
          this.loading = false;
          this.close();
        });
      }
    },

    incrementOrder() {
      this.item.order_num = parseInt(this.item.order_num, 10) + 1;
    },

    decrementOrder() {
      this.item.order_num = parseInt(this.item.order_num, 10) - 1;
    },
  },
};
</script>
