<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.new_layer") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.layer_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        v-if="hasUserPermissionToViewEditOrManage('LAYERS')"
        class="mb-5"
        color="primary"
        @click="add"
      >
        <v-icon left>mdi-plus-box-multiple</v-icon>
        {{ $t("configurations.new_layer") }}
      </v-btn>
    </div>
    <List @remove="remove" @edit="edit" :key="componentKey" />
    <CreateLayerDialog :open.sync="openAddLayerDialog" />
    <EditLayerDialog :open.sync="openEditLayerDialog" :item="selectedLayer" />
    <DeleteLayerDialog
      :open.sync="openDeleteLayerDialog"
      :item="selectedLayer"
    />
  </div>
</template>

<script>
import List from "./Layers/List";
import CreateLayerDialog from "./Layers/CreateLayerDialog";
import EditLayerDialog from "./Layers/EditLayerDialog";
import DeleteLayerDialog from "./Layers/DeleteLayerDialog";
export default {
  components: {
    List,
    CreateLayerDialog,
    EditLayerDialog,
    DeleteLayerDialog,
  },
  data() {
    return {
      componentKey: 0,
      openAddLayerDialog: false,
      openEditLayerDialog: false,
      openDeleteLayerDialog: false,
      selectedLayer: null,
    };
  },
  watch: {
    openAddLayerDialog(val) {
      if (val == false) this.forceRerender();
    },
    openEditLayerDialog(val) {
      if (val == false) this.forceRerender();
    },
    openDeleteLayerDialog(val) {
      if (val == false) this.forceRerender();
    },
  },
  methods: {
    add() {
      this.openAddLayerDialog = true;
    },
    edit(layer) {
      this.selectedLayer = layer;
      this.openEditLayerDialog = true;
    },
    remove(layer) {
      this.selectedLayer = layer;
      this.openDeleteLayerDialog = true;
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
