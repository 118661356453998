<template>
   <Layers />
</template>

<script>
import Layers from "@/components/Configurations/Layers";

export default {
  components: {
    Layers,
  },
};
</script>
