<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("configurations.edit_layer") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="overflow:auto;height:50vh">
        <v-form ref="form" class="mt-10" v-if="!!itemToEdit">
          <v-text-field
            counter="50"
            maxlength="50"
            v-model="itemToEdit.code"
            required
            :label="$t('global.code')"
            :rules="codeRules"
            outlined
          />
          <v-text-field
            counter="150"
            maxlength="150"
            v-model="itemToEdit.name"
            required
            :label="$t('global.name')"
            :rules="nameRules"
            outlined
          />
          <v-text-field
            counter="250"
            maxlength="250"
            v-model="itemToEdit.description"
            :label="$t('global.description')"
            outlined
          />
          <v-switch
            v-model="itemToEdit.active"
            :label="$t('global.active_by_omission')"
            outlined
          ></v-switch>
          <v-select
            v-model="itemToEdit.port_locode"
            :items="locodes"
            :label="$t('global.port_scale')"
            item-text="name"
            item-value="code"
            outlined
            disabled
          />
          <v-select
            v-model="itemToEdit.type"
            :items="types"
            required
            :label="$t('global.layer_type')"
            item-text="text"
            item-value="value"
            :rules="typeRules"
            outlined
          />
          <v-text-field
            v-model="itemToEdit.order_num"
            type="number"
            :label="$t('global.order')"
            :rules="orderRules"
            outlined
            onkeydown="return event.keyCode !== 69"
          >
            <template v-slot:append-outer>
              <v-btn depressed icon @click="incrementOrder">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn depressed icon @click="decrementOrder">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div v-if="itemToEdit.type == 'WMS'">
            <v-text-field
              counter="200"
              maxlength="200"
              v-model="itemToEdit.source_url"
              required
              :label="$t('global.service_url')"
              :rules="wmsUrlRules"
              outlined
            />
            <v-text-field
              counter="200"
              maxlength="200"
              v-model="wms_layers"
              required
              :label="$t('global.layer_layers')"
              :rules="wmsLayersRules"
              outlined
            />
            <v-text-field
              counter="200"
              maxlength="200"
              v-model="wms_format"
              required
              :label="$t('global.format')"
              :rules="wmsFormatRules"
              outlined
            />
            <v-select
              v-model="wms_crs"
              :items="epsgs"
              required
              :label="$t('global.epsg')"
              item-text="text"
              item-value="value"
              :rules="wmsEpsgRules"
              outlined
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save">
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import configs from "@/helpers/configs";
export default {
  props: ["open", "item"],
  data() {
    return {
      codeRules: [(v) => !!v || this.$t('global.code_rules')],
      nameRules: [(v) => !!v || this.$t('global.names_rules')],
      typeRules: [(v) => !!v || this.$t('global.type_rules')],
      wmsUrlRules: [(v) => !!v || this.$t('global.wms_url_rules')],
      wmsLayersRules: [(v) => !!v || this.$t('global.wms_layer_rules')],
      wmsFormatRules: [(v) => !!v || this.$t('global.wms_format_rules')],
      wmsEpsgRules: [(v) => !!v || this.$t('global.wms_epsg_rules')],
      orderRules: [],
      locodes: configs.getLocodes(),
      types: [
        {
          text: "WMS",
          value: "WMS",
        },
      ],
      epsgs: [
        {
          text: "EPSG:4326",
          value: "EPSG:4326",
        },
        {
          text: "EPSG:3857",
          value: "EPSG:3857",
        },
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    itemToEdit: {
      get() {
        return this.item;
      },

      set(val) {
        this.$emit("update:item", val);
      },
    },

    wms_layers: {
      get() {
        let currentContent = JSON.parse(this.itemToEdit.content);
        return currentContent["layers"];
      },
      set(val) {
        let currentContent = JSON.parse(this.itemToEdit.content);
        currentContent["layers"] = val;
        this.itemToEdit.content = JSON.stringify(currentContent);
      },
    },
    wms_format: {
      get() {
        let currentContent = JSON.parse(this.itemToEdit.content);
        return currentContent["format"];
      },
      set(val) {
        let currentContent = JSON.parse(this.itemToEdit.content);
        currentContent["format"] = val;
        this.itemToEdit.content = JSON.stringify(currentContent);
      },
    },
    wms_crs: {
      get() {
        let currentContent = JSON.parse(this.itemToEdit.content);
        return currentContent["crs"];
      },
      set(val) {
        let currentContent = JSON.parse(this.itemToEdit.content);
        currentContent["crs"] = val;
        this.itemToEdit.content = JSON.stringify(currentContent);
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        // copy this value so that the server understands our request
        this.itemToEdit.locode = this.itemToEdit.port_locode;
        this.$store.dispatch("layers/UPDATE_LAYER", this.itemToEdit).then(() => {
          this.loading = false;
          this.close();
        });
      }
    },

    incrementOrder() {
      this.itemToEdit.order_num = parseInt(this.itemToEdit.order_num, 10) + 1;
    },

    decrementOrder() {
      this.itemToEdit.order_num = parseInt(this.itemToEdit.order_num, 10) - 1;
    },
  },
};
</script>
